.Suggestions {
    display: grid;
    position: absolute;
    margin-top: -1rem;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 1rem 1rem;

    input {
        color: inherit;
        cursor: pointer; }

    .active {
        text-decoration: underline; } }

.light {
    .Suggestions {
        background: rgba(255, 255, 255, 0.5); } }
