.Widgets {
    padding: 0;
    text-align: center;
    pointer-events: none;

    .container {
        position: relative;
        width: 100%;
        height: 100%;

        .Slot > * {
            margin: 1rem;
            pointer-events: all; } }

    h1, h2, h3, h4 {
        line-height: 1;
        margin: 0; }

    .weight-override {
        h1, h2, h3, h4 {
            font-weight: inherit; } } }
