.Links {
    column-gap: 1em;
    display: inline-grid;

    a {
        display: block;
        margin: 0.25em;
        white-space: nowrap;

        &:hover {
            text-decoration: none; }

        &:hover {
            .LinkText {
                text-decoration: underline; } }

        img {
            height: 1em; } } }
