.Settings {
    h1 {
        margin: 2rem 0;

        svg {
            fill: #00b7ff;
            width: 100%; } }

    h2, h3, h4 {
        color: #212121; }

    h2, h3 {
        margin-bottom: 0; }

    .plane {
        border-radius: 0 1rem 1rem 0;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
        background-color: white;
        max-width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 0 1rem;
        position: absolute;
        width: 330px;
        transition: transform 0.15s ease-out; }

    label {
        display: block;
        margin: 1rem 0;

        &:last-child {
            margin-bottom: 0; } }

    label + .info {
        font-size: 0.9em;
        margin-top: -0.75rem; }

    input[type=email],
    input[type=file],
    input[type=number],
    input[type=text],
    input[type=time],
    input[type=url],
    textarea,
    select {
        border: 1px solid #bdc3c7;
        border-radius: 0.2em;
        color: inherit;
        display: block;
        margin: 0.5em 0;
        padding: 0.5em;
        width: 100%;
        overflow: hidden;

        &.primary {
            background-color: #ecf0f1;
            border: 0.25em solid #ecf0f1; }

        & + .input--error {
            display: none; }

        &:invalid + .input--error {
            display: block; } }

    input[type=radio],
    input[type=checkbox] {
        margin-right: 0.5rem; }

    input[type=color] {
        display: block; }

    .button {
        border: 0;
        border-radius: 2em;
        color: white;
        cursor: pointer;
        display: inline-block;
        padding: 0.5em 1em;
        text-decoration: none;
        transition: background 0.25s ease-out;
        text-align: center;
        font-weight: 500;
        &:disabled {
            background-color: #bdc3c7;
            cursor: default; }

        &--primary {
            background-color: #00b7ff;
            &:hover {
                background: darken(#00b7ff, 10%); }
            &:active {
                background: darken(#00b7ff, 20%); } }

        &--secondary {
            background: darken(#bdc3c7, 20%);
            &:hover {
                background: darken(#bdc3c7, 30%); }
            &:active {
                background: darken(#bdc3c7, 40%); } } }

    hr {
        border: 1px solid #ecf0f1;
        margin: 1rem 0; } }

.button--icon {
    background-color: #ecf0f1;
    border: none;
    border-radius: 1em;
    display: inline-block;
    padding: 0.5em;
    line-height: 1;
    height: 2em;
    width: 2em;
    color: black;
    cursor: pointer;
    transition: background-color 0.25s ease-out;

    &:hover {
        background-color: #bdc3c7 !important; }

    &.button--primary {
        background-color: #00b7ff !important;
        color: white;
        padding: 0.5em;

        &:hover {
            background-color: darken(#00b7ff, 20%) !important; } } }
