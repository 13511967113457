.Giphy {
    background-color: black;
    overflow: hidden;

    .gif {
        background-position: 50% 50%;
        transition: opacity 0.25s ease-out; }

    .darken {
        background-color: rgba(0, 0, 0, 0.25); }

    .credit {
        position: absolute;
        left: 1rem;
        bottom: 1rem; } }
