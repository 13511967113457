.Time.Analogue {
    text-align: center;

    svg {
        max-width: 10em;
        width: 100%;
        height: auto;

        circle {
            &.bezel {
                fill: transparent;
                stroke-width: 2; } }

        line {
            stroke-linecap: round;
            stroke-width: 2;
            transform-origin: 50px 50px;
            transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);

            &.hours {
                stroke-width: 3; }

            &.minutes {
                stroke-width: 2; }

            &.seconds {
                stroke-width: 1.5; } } } }
