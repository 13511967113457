.Modal-container {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: end;
  justify-content: center;
}

.Modal {
  background: white;
  border-radius: 1rem 1rem 0 0;
  padding: 2rem;
  min-width: 300px;
  max-width: 500px;
}

.Modal-footer {
  text-align: center;
}
