.Search {
    display: block;
    position: relative;

    input {
        background-color: transparent;
        border: 0;
        font-family: inherit;
        font-size: 1.1em;
        outline: none;
        padding: 0.15em 0;
        text-align: center;
        text-shadow: inherit; }

    > input {
        margin: 1rem 0;
        width: 100%;
        border-bottom-style: solid;
        border-bottom-width: 2px; } }

