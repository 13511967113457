.TodoItem {
    // Some weird element size bug
    white-space: nowrap;
    direction: rtl;

    a {
        display: inline-block;
        font-size: 0.75em;
        margin: 0.25em 0.5em; }

    span {
        direction: initial;
        display: inline-block; }

    a.delete {
        display: none; }

    span:focus {
        & ~ a.delete {
            display: inline-block; }
        & ~ a.complete {
            display: none; } } }
