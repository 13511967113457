.Quote {
    overflow-y: hidden;
    max-height: 33vh;

    &:hover {
        overflow-y: auto; }

    h4 {
        line-height: 1.25 !important; }

    sub {
        bottom: 0; } }
