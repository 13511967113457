.Overlay {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 1.25rem;
    transition: all 0.25s ease-out;
    font-size: 1.1rem;

    .on-hover {
        opacity: 0;
        transition: all 0.25s ease-out; }

    &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.65);

        .on-hover {
            opacity: 1; }

        svg {
            color: #00b7ff;
            filter: none; } }

    a, span {
        margin: 0 0.25em; }

    svg, i {
        transition: stroke 0.25s ease-out; } }

.light .Overlay:hover {
    background-color: rgba(255, 255, 255, 0.65); }
