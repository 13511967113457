.Weather {

    .summary {
        cursor: pointer;

        i {
            margin: 0 0.5em; } }

    .low {
        opacity: 0.7; }

    .details {
        font-size: 1rem;
        line-height: 1.5;

        dt {
            font-weight: 700; }
        dd {
            margin: 0; } } }
