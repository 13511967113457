.LiteratureClock {
    &.center {
        blockquote, cite {
            text-align: center; } }

    blockquote {
        text-align: justify;
        line-height: 1.6em;
        max-width: 50vw; }

    span {
        opacity: 0.9; }

    strong {
        opacity: 1;
        font-size: 1.5em; }

    cite {
        display: block;
        text-align: right;
        font-style: normal;
        font-size: 0.7em;
        margin-right: 2rem;

        #author {
 } } }            // font-style: italic
