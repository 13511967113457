.Settings .Widget {
    border: none;
    border-radius: 0.5rem;
    background: #ecf0f1;
    margin: 1em 0;
    padding: 1em;

    h4 {
        margin: 0; }

    .title--buttons {
        button {
            float: right;
            margin: -0.25em 0 0 0.5em; }

        h4 {
            cursor: pointer; } }

    .settings {
        margin: 1.5rem 0; }

    p:last-child {
        margin-bottom: 0; }

    .button--icon {
        background-color: white; } }
