* {
    box-sizing: border-box; }

html, body {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    line-height: 1.25;
    color: #5a636a; }

// Handle init background color, before settings are loaded
// Fallback to system theme
html {
    background-color: black; }
@media (prefers-color-scheme: light) {
    html {
        background-color: white; } }
// Choose theme if syncronously injected (see `target/<target>/index.html`)
html.theme-light {
    background-color: white; }
html.theme-dark {
    background-color: black; }

a {
    color: #00b7ff;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline; } }

h1 {
    font-size: 4em;
    margin-bottom: 0; }

h2 {
    font-size: 2em; }

h3 {
    font-size: 1.5em; }

h4 {
    font-size: 1.25em; }

h5 {
    font-size: 1em;
    margin: 0; }

i {
    font-family: Arial, sans-serif;
    line-height: 1;
    vertical-align: middle;

    svg {
        height: 1em;
        width: auto;
        overflow: visible !important; } }

.text {
    &--grey {
        color: #bdc3c7; } }

.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
