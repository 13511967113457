.Dashboard {
    overflow: hidden;

    svg {
        filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.25)); }

    a {
        color: inherit; }

    input {
        color: inherit;
        &::placeholder {
            color: inherit;
            opacity: 0.5; } }

    &.light {
        background-color: white;
        color: #111;
        .theme-fill {
            fill: #111; }
        .theme-stroke {
            stroke: #111; }
        input {
            border-color: #111; } }

    &.dark {
        background-color: black;
        color: white;
        .theme-fill {
            fill: white; }
        .theme-stroke {
            stroke: white; }
        input {
            border-color: white; } } }
